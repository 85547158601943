// material
import { useTheme } from '@mui/material/styles';
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch'
        },
        body: {
          width: '100%',
          height: '100%'
        },
        '#root': {
          width: '100%',
          height: '100%'
        },
        '.MuiCard-root.warning': {
          background: theme.palette.warning.main
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            }
          }
        },
        textarea: {
          '&::-webkit-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::-moz-placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          },
          '&:-ms-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::placeholder': {
            color: theme.palette.text.disabled
          }
        },

        img: { display: 'block', maxWidth: '100%' },

        // Lazy Load Img
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms'
        },
        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)'
        },

        // Fullcalendar

        '.fc-timeline-slot-label': {
          color: '#637381'
        },
        '.fc table': {
          fontSize: '16px !important'
        },
        '.fc-timeline-slot': {
          fontSize: '10px'
        },
        '.fc-event-title.fc-sticky': {
          position: 'static'
        },
        '.fc .fc-button-primary': {
          backgroundColor: '#0077B6 !important',
          border: 'none !important'
        },
        '.fc .fc-button-primary.fc-button-active': {
          backgroundColor: '#005a8a !important'
        },
        'th .fc-day-sat, th .fc-slot-sat, .fc-resourceTimelineSesson-view .fc-slot-sat': {
          backgroundColor: '#3788d829'
        },
        'th .fc-day-sun, th .fc-slot-sun, .fc-resourceTimelineSesson-view .fc-slot-sun': {
          backgroundColor: '#3788d849'
        },
        /* td.fc-timeline-slot:nth-child(2n) { border: 0!important; } */
        '.fc .fc-timeline-event': {
          borderRadius: '50px !important',
          height: '30px',
          padding: '2px !important'
        },
        '.fc .fc-timeline-events': {
          paddingBottom: '1px !important'
        },
        '.fc .fc-timeline-header-row-chrono .fc-timeline-slot-frame': {
          justifyContent: 'center !important'
        },
        '.fc .fc-timeline-event-harness': { marginTop: '2px !important' },
        '.fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events': { paddingBottom: '2px' },

        '.fc-timeline-event:not(.fc-event-end):after, .fc-timeline-event:not(.fc-event-start):before': {
          border: '8px solid #000',
          margin: '0 2px',
          borderTopColor: 'transparent',
          borderBottomColor: 'transparent'
        },

        '.fc-datagrid-cell.fc-resource .fc-datagrid-cell-main': {
          fontSize: '12px',
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          marginTop: '0px',
          lineHeight: '18px'
        },
        '.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr': {
          '@media(max-width: 860px)': {
            flexDirection: 'column',
            gap: '8px',

            '.fc-toolbar-chunk:last-of-type': {
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              gap: '4px',
              flexWrap: 'wrap'
            }
          }
        },
        '&.ck.ck-balloon-panel': {
          zIndex: `${theme.zIndex.modal} !important`
        },
        // Fullcalendar
        '.fc-time-grid-container .fc-slats td:hover, .fc-time-grid-container .fc-major td:hover': {
          backgroundColor: '#3788d88a'
        },
        '.hover': {
          backgroundColor: '#3788d88a'
        },
        '.overlay': {
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
          pointerEvents: 'none'
        },
        '.overlay-cell': {
          position: 'absolute',
          pointerEvents: 'auto',
          transition: 'background-color 0.2s'
        },
        '.overlay-cell.hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        },
        '.hover-table': {
          backgroundColor: 'grey',
          overflow: 'hidden'
        },
        '.hover-table tr:hover': {
          backgroundColor: 'rgba(215, 215, 215, 0.29)'
        },
        '.hover-table td, .hover-table th': {
          position: 'relative'
        },
        '.hover-table td:hover::after, .hover-table th:hover::after': {
          content: '""',
          position: 'absolute',
          backgroundColor: 'rgba(215, 215, 215, 0.29)',
          left: 0,
          top: '-5000px',
          height: '10000px',
          width: '100%',
          zIndex: -1
        },
        '.hover-table td': {
          border: 'none'
        },
        '.fc-timeline-slot-lane:hover': {
          background:
            '#3788d81c linear-gradient(#3788d88a, #3788d88a) no-repeat left var(--top, 0px) / 100% var(--height, 0px)'
        },
        '.fc-timeline-lane.hover': {
          backgroundColor: '#3788d81c'
        },

        '.fc-timeline-slot-lane.fc-slot-sat:hover': {
          background:
            '#3788d86e linear-gradient(#3788d86e, #3788d86e) no-repeat left var(--top, 0px) / 100% var(--height, 0px)'
        },
        '.fc-timeline-slot-lane.fc-slot-sun:hover': {
          background:
            '#3788d861 linear-gradient(#3788d861, #3788d861) no-repeat left var(--top, 0px) / 100% var(--height, 0px)'
        },
        '.fc .fc-timeline-event.price-list-gap': {
          borderRadius: '0 !important',
          backgroundColor: '#cecece',
          borderColor: '#cecece',
          marginRight: '0 !important',
          height: 36,
          top: -2,
          opacity: 0.5,
          '&:before': {
            display: 'none'
          }
        },
        // REACT-DATES
        '.app': {
          '.DayPicker__withBorder': {
            borderRadius: 8,
            boxShadow: '0 2px 10px rgba(0,0,0,0.2)'
          },
          '.DateRangePickerInput_arrow': {
            display: 'none'
          },

          '.drp-error .DateRangePicker .DateInput': {
            '&_input': {
              borderColor: theme.palette.error.main,

              '&::-webkit-input-placeholder': {
                color: theme.palette.error.main
              },
              '&::-moz-placeholder': {
                opacity: 1,
                color: theme.palette.error.main
              },
              '&:-ms-input-placeholder': {
                color: theme.palette.error.main
              },
              '&::placeholder': {
                color: theme.palette.error.main
              }
            }
          },
          '.DateRangePicker': {
            width: '100%',

            '&_picker': {
              '&:before': {
                '@media(min-width: 900px)': {
                  content: '""',
                  display: 'block',
                  height: '100%',
                  width: 1,
                  backgroundColor: 'rgba(145, 158, 171, 0.24)',
                  left: '50%',
                  top: 0,
                  position: 'absolute',
                  zIndex: 2
                }
              }
            },

            '.DateRangePickerInput': {
              borderRadius: 8,
              height: 56,
              display: 'inline-flex',
              gap: 16,
              alignItems: 'center',
              padding: 0,
              width: '100%'
            },

            '.DateInput': {
              height: '100%',
              flex: 1,

              '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                zIndex: 5,
                pointerEvents: 'none',
                width: 24,
                height: 24,
                top: '50%',
                right: 16,
                marginTop: -12,
                background: 'url(/static/icons/calendar.svg) no-repeat 50% 50% / contain'
              },

              '&_input': {
                padding: '16.5px 14px',
                border: '1px solid #dbdbdb',
                borderRadius: 8,
                height: 56,
                height: '100%',
                fontSize: 16,
                textAlign: 'left',
                transition: 'all .3s ease',
                fontFamily: theme.typography.fontFamily,

                '&__focused': {
                  borderColor: theme.palette.primary.main,
                  outline: `1px solid ${theme.palette.primary.main}`
                }
              },
              '&_fang': {
                display: 'none'
              }
            }
          },
          '.DayPicker_weekHeader': {
            top: 52,
            color: theme.palette.grey[600]
          },
          '.DayPicker_transitionContainer': {
            minHeight: 330,
            borderRadius: 8
          },
          '.CalendarMonth_caption': {
            paddingTop: 16,
            strong: {
              fontSize: 16,
              fontWeight: 600,
              textTransform: 'capitalize',
              color: theme.palette.grey[800]
            }
          },
          '.CalendarMonth': {
            width: 309,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          },
          '.CalendarDay__blocked_calendar, .CalendarDay__blocked_calendar:active, .CalendarDay__blocked_calendar:hover':
            {
              backgroundColor: 'transparent',
              color: '#cccccc'
            },
          '.DateRangePicker_picker': {
            zIndex: '9999 !important',
            borderRadius: 8,
            border: 'none',

            '.CalendarDay': {
              '&__selected_end': {},
              '&__selected_start': {
                position: 'relative',

                '&:before': {
                  content: '""',
                  position: 'absolute',
                  height: '100%',
                  display: 'block',
                  backgroundColor: 'red',
                  width: '48%',
                  top: 0,
                  right: 0,
                  zIndex: -1
                }
              },

              '&__default': {
                border: 'none',
                padding: '1px 0',

                '&:hover': {
                  backgroundColor: 'transparent'
                }
              },
              '&__outside': {
                border: 'none',
                color: '#afaeae',
                backgroundColor: 'transparent'
              },
              '&__selected, &__selected:active, &__selected:hover': {
                backgroundColor: 'transparent',
                '&.CalendarDay__outside': {
                  color: '#ffffff'
                }
              },
              '&__selected_span': {
                backgroundColor: 'transparent',
                borderRadius: 0,
                '&.CalendarDay__outside': {
                  color: '#ffffff'
                },
                '&:active, &:hover': {
                  backgroundColor: 'rgba(0, 119, 182, 0.12)'
                }
              },
              '&__hovered_span': {
                backgroundColor: 'transparent',
                color: '#000000',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: '#ffffff'
                }
              }
            }
          },
          '.CalendarMonthGrid__horizontal': {
            left: 0,
            position: 'relative'
          }
        }
      }}
    />
  );
}
