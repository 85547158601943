import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ROUTES } from 'src/routes/api';
import queryString from 'query-string';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isReservationsLoading: false,
  error: false,
  reservationsList: {
    data: [],
    pagination: {}
  },
  filters: {},
  activeFilters: {}
};

const slice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    startReservationsLoading(state) {
      state.isReservationsLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET RESERVATIONS
    getReservationsSuccess(state, action) {
      state.isLoading = false;
      state.isReservationsLoading = false;
      state.reservationsList = {
        data: action.payload.reservations,
        pagination: action.payload.meta.pagination
      };
    },

    getFiltersSuccess(state, action) {
      state.filters = action.payload;
    },

    setActiveFilters(state, action) {
      state.activeFilters = action.payload;
    },

    reset(state) {
      Object.keys(state).forEach((key) => (state[key] = initialState[key]));
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setActiveModal } = slice.actions;

// ----------------------------------------------------------------------

export function getReservationsList(page = 1, page_size = 10, filters) {
  return async (dispatch, getState) => {
    const state = getState();

    if (!state.reservations.isReservationsLoading) {
      dispatch(slice.actions.startReservationsLoading());
      dispatch(slice.actions.startLoading());

      const activeFilters = filters || state.reservations.activeFilters;

      try {
        const {
          data: { reservations, meta }
        } = await axios.get(API_ROUTES.reservations.list, {
          params: {
            page,
            page_size,
            ...activeFilters
          }
        });
        dispatch(slice.actions.getReservationsSuccess({ reservations, meta }));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function getReservationFilters() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const {
        data: { filters }
      } = await axios.get(API_ROUTES.filters);
      dispatch(slice.actions.getFiltersSuccess(filters));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setActiveFilters(activeFilters, setSearchParams) {
  return async (dispatch, getState) => {
    const { reservations } = getState();
    const { pageSize } = reservations.reservationsList.pagination;

    // update search params
    if (typeof setSearchParams === 'function') {
      const params = queryString.stringify(activeFilters, { arrayFormat: 'bracket' });

      setSearchParams(params);
    }

    if (!activeFilters.sort || !activeFilters.order) {
      activeFilters.sort = 'created_at';
      activeFilters.order = 'DESC';
    }

    dispatch(slice.actions.setActiveFilters(activeFilters));
    dispatch(getReservationsList(1, pageSize, activeFilters));
  };
}

export function reset() {
  return async (dispatch) => {
    dispatch(slice.actions.reset());
  };
}
